export enum ChartColumnType {
    STRING = 'STRING',
    DATE = 'DATE',
}

export interface IChart {
    id: number;
    columns: { name: string; key: string; type: ChartColumnType }[];
    data: (object & { rowId: number })[];
    totals?: object;
}
