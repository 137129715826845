import { Grid } from '@mui/material';
import { FC, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Page } from '../../../shared';
import { Chart, DashboardFilter } from '../../components';
import { useChartsByDashboard, useDashboard } from '../../hooks';
import { DashboardFilterType, IAppliedDashboardFilterDto } from '../../models';
import { endOfDay, startOfDay } from 'date-fns';

export const DashboardPage: FC = () => {
    const params = useParams();
    const id = Number(params.id);
    const { data: dashboard, isLoading } = useDashboard(id);
    const { data: charts = [] } = useChartsByDashboard(id);
    const availableFilters = dashboard?.filters || [];
    const hasFilters = availableFilters.length > 0;
    const [filters, setFilters] = useState<IAppliedDashboardFilterDto[]>([]);

    const formattedFilters = useMemo(() => {
        return formatFilters(filters);
    }, [filters]);

    return (
        <Page
            title={dashboard?.name}
            filter={
                hasFilters && (
                    <DashboardFilter filters={filters} availableFilters={availableFilters} onChange={setFilters} />
                )
            }
            loading={isLoading}
        >
            {dashboard && (
                <Grid container gap={4}>
                    {charts.map((chart) => (
                        <Grid item xs={12} key={chart.id}>
                            <Chart id={chart.id} dashboardId={dashboard.id} filters={formattedFilters} />
                        </Grid>
                    ))}
                </Grid>
            )}
        </Page>
    );
};

function formatFilters(filters: IAppliedDashboardFilterDto[]): IAppliedDashboardFilterDto[] {
    return filters.map((filter) =>
        filter.type === DashboardFilterType.DATE
            ? {
                  ...filter,
                  startDate: startOfDay(new Date(filter.startDate)).toISOString(),
                  endDate: endOfDay(new Date(filter.endDate)).toISOString(),
              }
            : filter,
    );
}
