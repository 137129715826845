import { RefreshOutlined } from '@mui/icons-material';
import { IconButton, Stack } from '@mui/material';
import React, { FC, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ControlledDatePicker, ControlledSelect, FormGrid, Options, OptionsToggle } from '../../../shared';
import {
    DashboardFilterType,
    IAppliedDashboardFilterDto,
    IAppliedDateFilterDto,
    IAppliedSelectFilterDto,
    IDashboardFilter,
} from '../../models';

interface Props {
    availableFilters: IDashboardFilter[];
    onChange: (filters: IAppliedDashboardFilterDto[]) => void;
    filters: IAppliedDashboardFilterDto[];
}

type DashboardFilterForm = Record<string, string[]> & { startDate?: string; endDate?: string };

export const DashboardFilter: FC<Props> = ({ availableFilters, filters, onChange }) => {
    const { t } = useTranslation();

    const [options, setOptions] = useState<Options>({
        filter: { type: 'title', label: t('filter') },
        ...mapAvailableFilterToFilterOptions(availableFilters),
    });

    const form = useForm();

    const hasFilterFormChanged = useHasFilterFormChanged(form, filters);

    const onFilterChange = useCallback(
        (formValues: DashboardFilterForm) => {
            onChange(mapFormValuesToAppliedFilters(formValues));
        },
        [onChange],
    );

    useEffect(() => {
        const activeOptions = getActiveOptions(availableFilters, options);

        filters.forEach((filter) => {
            if (filter.type === DashboardFilterType.DATE) {
                if (activeOptions.includes('date')) {
                    form.setValue('startDate', filter.startDate);
                    form.setValue('endDate', filter.endDate);
                } else {
                    form.setValue('startDate', undefined);
                    form.setValue('endDate', undefined);
                    onFilterChange(form.getValues());
                }
            }

            if (filter.type === DashboardFilterType.SELECT) {
                if (activeOptions.includes(filter.target)) {
                    form.setValue(filter.target, filter.values);
                } else {
                    form.setValue(filter.target, []);
                    onFilterChange(form.getValues());
                }
            }
        });
    }, [filters, form, options, availableFilters, onFilterChange]);

    useEffect(() => {
        setOptions({
            filter: { type: 'title', label: t('filter') },
            ...mapAvailableFilterToFilterOptions(availableFilters),
        });
    }, [availableFilters]);

    return (
        <Stack direction="row" spacing={1} alignItems="center">
            <OptionsToggle options={options} onChange={setOptions} />
            <FormGrid containerProps={{ spacing: 1, py: 1 }} sx={{ '&.MuiGrid-item': { pr: 1 } }}>
                {availableFilters.map((filter) => {
                    if (!options[filter.name]?.active) return <Fragment key={filter.name} />;

                    return (
                        <Fragment key={filter.name}>
                            {filter.type === DashboardFilterType.DATE && (
                                <Stack direction="row" spacing={1}>
                                    <ControlledDatePicker
                                        name="startDate"
                                        label={t('startDate')}
                                        control={form.control}
                                        size="small"
                                    />
                                    <ControlledDatePicker
                                        name="endDate"
                                        label={t('endDate')}
                                        control={form.control}
                                        size="small"
                                    />
                                </Stack>
                            )}
                            {filter.type === DashboardFilterType.SELECT && (
                                <ControlledSelect
                                    name={filter.target || filter.name}
                                    label={filter.name}
                                    form={form}
                                    options={filter.values || []}
                                />
                            )}
                        </Fragment>
                    );
                })}
            </FormGrid>
            {hasFilterFormChanged && (
                <IconButton onClick={form.handleSubmit(onFilterChange)}>
                    <RefreshOutlined color="primary" />
                </IconButton>
            )}
        </Stack>
    );
};

function useHasFilterFormChanged(
    form: UseFormReturn<DashboardFilterForm>,
    appliedFilters: IAppliedDashboardFilterDto[],
): boolean {
    const formValues = form.watch();

    return useMemo(() => {
        const newlyAppliedFilters = mapFormValuesToAppliedFilters(formValues);
        return JSON.stringify(newlyAppliedFilters) !== JSON.stringify(appliedFilters);
    }, [appliedFilters, formValues]);
}

function mapFormValuesToAppliedFilters(filterForm: DashboardFilterForm): IAppliedDashboardFilterDto[] {
    const { startDate, endDate, ...rest } = filterForm;

    const appliedDateFilters: IAppliedDateFilterDto[] = [];
    if (startDate && endDate) {
        appliedDateFilters.push({ type: DashboardFilterType.DATE, startDate, endDate });
    }

    const appliedSelectFilters: IAppliedSelectFilterDto[] = Object.entries(rest)
        .map(
            ([target, values]): IAppliedSelectFilterDto => ({
                type: DashboardFilterType.SELECT,
                target,
                values,
            }),
        )
        .filter((appliedFilter) => appliedFilter.values?.length);

    return [...appliedDateFilters, ...appliedSelectFilters];
}

function mapAvailableFilterToFilterOptions(availableFilters: IDashboardFilter[]) {
    return availableFilters.reduce((filters, { name }) => ({ ...filters, [name]: { active: false, label: name } }), {});
}

function getActiveOptions(availableFilters: IDashboardFilter[], options: Options): string[] {
    return availableFilters
        .map(
            (filter) =>
                options[filter.name].active &&
                (filter.type !== DashboardFilterType.DATE ? filter.target || filter.name : 'date'),
        )
        .filter((item): item is string => !!item);
}
