import { useQuery } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { queryKeys } from '../../shared';
import { IChart } from '../models';
import { IAppliedDashboardFilterDto } from '../models';

async function getChart(id: number, dashboardId: number, filters: IAppliedDashboardFilterDto[]): Promise<IChart> {
    const { data } = await axios.post(`/reporting/chart/${id}`, {
        dashboardId,
        filters,
    });
    return data;
}

export const useChart = (id: number, dashboardId: number, filters: IAppliedDashboardFilterDto[]) => {
    return useQuery<IChart, AxiosError>({
        queryKey: [queryKeys.CHARTS, id, dashboardId, filters],
        queryFn: () => getChart(id, dashboardId, filters),
    });
};
